import { useState, useEffect } from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useContext } from "react";
import { LocationService } from "src/services";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StarIcon from "@mui/icons-material/Star";
import { AccountContext, AccountContextType } from "../contexts/ToggleAccountContext";
import { OpportunityPreview } from "../../types/models";
import { useRouter } from "next/router";
import ImageCarousel from "../Image/ImageCarousel";
import { useFilterContext } from "../contexts/FilterContext/FilterContext";
import DynamicContent from "./dynamicContents";

export default function OpportunityCard({
  opportunity,
  setRelatedOpportunities,
  imagePriority,
  RelatedOpportunities,
}: {
  opportunity: OpportunityPreview;
  imagePriority: boolean;
  setRelatedOpportunities?: (prev: OpportunityPreview[]) => OpportunityPreview[];
  RelatedOpportunities?: boolean;
}) {
  const [followed, setFollowed] = useState(!!opportunity.isFavorite);
  const [phase, setPhase] = useState("");
  const router = useRouter();
  const { isLogged, setShowLogin } = useContext<AccountContextType>(AccountContext);
  const { setOpportunities } = useFilterContext();

  /**
   * To avoid to refresh, we update manually the opportunities with the same location if the current opp
   * is liked or unliked
   */
  const updateSimilarOpportunities = (flag: boolean) => {
    const updateOpportunityFavoriteStatus = (op: OpportunityPreview) => {
      if (op.location.some((locId) => (opportunity.location || []).includes(locId))) {
        op.isFavorite = flag;
      }
      return op;
    };

    setOpportunities((prev: OpportunityPreview[]) => prev.map(updateOpportunityFavoriteStatus));

    if (setRelatedOpportunities) {
      setRelatedOpportunities((prev: OpportunityPreview[]) =>
        prev.map(updateOpportunityFavoriteStatus),
      );
    }
  };

  async function followAction(opportunity: OpportunityPreview, follow: boolean) {
    if (follow) {
      const res = await LocationService.followLocations(opportunity.location);
      if (res) {
        setFollowed(true);
        updateSimilarOpportunities(true);
      }
      return;
    }

    const res = await LocationService.unfollowLocations(opportunity.location);
    if (res) {
      setFollowed(false);
      updateSimilarOpportunities(false);
    }
  }

  const open = (opportunity: OpportunityPreview) => {

    // setRedirected(true);
    if (typeof window !== "undefined" && window.innerWidth < 768) {
      // For mobile devices, navigate in the same tab
      router.push(`/opportunity/${opportunity?.opportunity_id}`);
    } else {
      // For larger screens, open in a new tab
      window.open(`/opportunity/${opportunity?.opportunity_id}`, "_blank");
    }
  };

  useEffect(() => {
    setFollowed(!!opportunity.isFavorite);
  }, [opportunity.isFavorite]);

  useEffect(() => {
    const opportunityType = opportunity.type === "event" ? "ticket" : "deal";
    if (opportunity.difference >= 10) {
      setPhase(`10+ ${opportunityType}s left`);
    } else if (opportunity.difference < 10 && opportunity.difference >= 2) {
      setPhase(`Hurry up! Only ${opportunity.difference} ${opportunityType}s left!`);
    } else {
      setPhase(`1 ${opportunityType} left! Go get it!`);
    }
  }, [opportunity]);

  const expirationDate = new Date(opportunity.expiration_date);
  const now = new Date();
  const sixtyDaysAgo = new Date(now.getTime() - 60 * 24 * 60 * 60 * 1000); // Calculate the date 60 days ago
  expirationDate.setDate(expirationDate.getDate() + 1);
  const notPurchasable =
    (expirationDate <= now && expirationDate >= sixtyDaysAgo) || opportunity.difference <= 0;

  return (
    <div className="justiy-center relative w-full cursor-pointer overflow-hidden">
      {!RelatedOpportunities && (
        <div className="-mb-14 flex w-full flex-row-reverse pt-10">
          <button
            style={{
              zIndex: 2,
            }}
            className="-mt-45 mr-4"
          >
            {followed ? (
              <FavoriteIcon
                sx={{ color: "#e4d5aa", fontSize: "33px" }}
                onClick={() => {
                  followAction(opportunity, false);
                }}
              />
            ) : (
              <FavoriteBorderIcon
                sx={{
                  strokeWidth: "0.8",
                  color: "#e4d5aa",
                  fontSize: "33px",
                }}
                onClick={() => {
                  isLogged ? followAction(opportunity, true) : setShowLogin(true);
                }}
                className="border-brand-500"
              />
            )}
          </button>
        </div>
      )}
      <div
        className={"relative"}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault(); // Prevent default action
          open(opportunity);
        }}
      >
        {opportunity && RelatedOpportunities && (
          <div>
            {opportunity.media[0] ? (
              <img
                src={opportunity.media[0]}
                alt="opportunity-image"
                style={{
                  width: "300px",
                  height: "250px",
                  objectFit: "cover",
                  borderRadius: "16px",
                }}
              />
            ) : (
              <div
                className="align-center flex justify-center "
                style={{
                  backgroundColor: "#EAAC00",
                  padding: "20px",
                  Width: "300px",
                  height: "250px",
                  borderRadius: "16px",
                }}
              >
                <img
                  src={opportunity.business_image}
                  alt="business-image"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginTop: "30px",
                  }}
                />
              </div>
            )}
          </div>
        )}
        {opportunity && !RelatedOpportunities && (
          <ImageCarousel opportunity={opportunity} priority={imagePriority} square />
        )}
        <div className={"mt-1 flex sm:mt-3"}>
          <div className="w-full">
            <h3 className="text-xl font-bold">{opportunity.title}</h3>
            <div
              className="width-[280px] flex items-center justify-between"
              style={{ width: "100%" }}
            >
              <p
                className="text-[18px] text-black sm:mt-1 "
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {opportunity.business_name.length > 24
                  ? `${opportunity.business_name.slice(0, 24)}...`
                  : opportunity.business_name}
              </p>
              <div className="relative items-center">
                <div className="inline-flex items-start text-lg font-semibold text-black">
                  <>
                    {opportunity.rating !== null ? (
                      <div className="flex justify-center sm:mt-[5px]">
                        <StarIcon
                          sx={{
                            fontSize: "20px",
                            marginTop: "4px",
                          }}
                        />
                        <span className="mr-2 text-[18px]">{opportunity.rating.toFixed(1)}</span>
                      </div>
                    ) : (
                      <div className="mt-[5px] flex justify-center">
                        <StarBorderOutlinedIcon sx={{ fontSize: "20px", marginTop: "4px" }} />
                        <span className="mr-2 text-[18px]"> New</span>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
            {notPurchasable ? (
              <p>Sorry, it’s gone!</p>
            ) : (
              <DynamicContent opportunity={opportunity} phase={phase} />
            )}

            <div className="flex items-center">
              {opportunity.type !== "event" && (
                <p className="mr-2 text-[20px] line-through" style={{ color: "#656565" }}>
                  ${opportunity.value.toFixed(0)}
                </p>
              )}

              <p
                className={`mr-2 text-[20px] font-bold text-black ${
                  opportunity.type === "event" ? "sm:mt-[5px]" : ""
                }`}
              >
                {opportunity.price ? (
                  opportunity.type === "event" ? (
                    <>
                      ${opportunity.price.toFixed(0)}{" "}
                      <span className="text-[18px] font-light">
                        {" "}
                        {opportunity.price < opportunity.maxPrice ? "and up" : ""}{" "}
                      </span>
                    </>
                  ) : (
                    `$${opportunity.price.toFixed(0)}`
                  )
                ) : (
                  "Free"
                )}
              </p>
              {opportunity.type !== "event" ? (
                <div
                  style={{ fontSize: "22px", width: "120px" }}
                  className="brand-color-opaque flex h-10 content-center justify-center rounded-lg px-[6px] py-[6px] text-[15px] font-bold text-brand"
                >
                  <span
                    className="text-[20px]"
                    style={{
                      background:
                        "linear-gradient(104.63deg, #C19311 0%, #EBCB73 48.96%, #EAAC00 98.54%)",
                      WebkitBackgroundClip: "text",
                      MozBackgroundClip: "text",
                      backgroundClip: "text",
                      color: "transparent",
                    }}
                  >
                    {opportunity.value !== opportunity.price &&
                      (
                        ((opportunity.value - opportunity.price) / opportunity.value) *
                        100
                      ).toFixed()}
                    % OFF
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
